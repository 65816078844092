<template>
    <span v-if="value === 1" class="text-success">Activo</span>
    <span v-else class="text-secondary">Inactivo</span>
</template>

<script>
export default {
    name: 'SimpleStatus',
    props: {
        value: { type: Number, required: true },
    },
};
</script>

<style></style>
